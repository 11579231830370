@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.fixed-ratio' as *;
@use 'scss_mixins/tools.gradient' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.module-space' as *;
@use 'scss_mixins/tools.picture' as *;
@use 'scss_mixins/tools.typography' as *;

.quote {
  @include container-max;
  @include module-space('margin');

  position: relative;
}

.quote__quote-figure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: pxtorem(100px);
  padding-bottom: pxtorem(100px);

  @include mq(tablet) {
    padding-top: pxtorem(120px);
    padding-bottom: pxtorem(120px);
  }

  @include mq(desktop) {
    padding-top: pxtorem(160px);
    padding-bottom: pxtorem(160px);
  }

  @include mq(bigscreen) {
    padding-top: pxtorem(180px);
    padding-bottom: pxtorem(180px);
  }
}

.quote:not(.quote--has-image) .quote__quote-figure {
  background-color: var(--color-black);
}

.quote__blockquote {
  @include container-small;
  @include text-quote;

  @include mq(tablet) {
    padding-left: pxtorem(170px);
  }

  @include mq(desktop) {
    @include container-small;
  }
}

.quote__blockquote-inner {
  position: relative;
}

.quote__icon {
  position: absolute;
  font-size: pxtorem(50px);

  @include mq(tablet) {
    font-size: pxtorem(80px);
  }

  @include mq(desktop) {
    font-size: pxtorem(120px);
  }

  @include mq(bigscreen) {
    font-size: pxtorem(160px);
  }
}

.quote__icon--left {
  top: 0;
  left: 0;
  transform: translateY(calc(-100% - pxtorem(20px)));

  @include mq(tablet) {
    transform: translateX(calc(-100% - pxtorem(20px))) translateY(-50%);
  }

  @include mq(desktop) {
    transform: translateX(calc(-100% - pxtorem(50px))) translateY(-50%);
  }
}

.quote__icon--right {
  right: 0;
  bottom: 0;
  transform: translateY(calc(100% + pxtorem(20px)));

  @include mq(desktop) {
    transform: translateX(calc(100% + pxtorem(50px))) translateY(50%);
  }
}

.quote__quote-figcaption {
  @include container-large;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: pxtorem(110px);

  @include mq(tablet) {
    align-items: flex-end;
  }

  @include mq(bigscreen) {
    margin-top: pxtorem(130px);
  }
}

.quote__caption,
.quote__cite {
  width: 100%;

  @include mq(tablet) {
    max-width: 46%;
  }

  @include mq(desktop) {
    max-width: 32%;
  }
}

.quote__caption {
  @include text-intro;
}

.quote__cite {
  margin-top: pxtorem(5px);

  @include mq(desktop) {
    margin-top: pxtorem(10px);
  }
}

// image

.quote__picture {
  @include picture-background;
}

.quote__picture::before {
  @include gradient-full;
}

.quote__picture::after {
  @include gradient-full(0.4, var(--color-cyan));
}

.quote__image {
  @include image-background;
}

.quote__picture-figcaption {
  @include container-large;

  position: absolute;
  right: 0;
  bottom: pxtorem(30px);
  z-index: 1;
  text-align: left;

  @include mq(tablet) {
    bottom: pxtorem(40px);
    text-align: right;
  }

  @include mq(desktop) {
    bottom: pxtorem(50px);
  }
}
