@mixin link-hover-default($defaultActive: false, $color: currentColor) {
  background-image: linear-gradient(180deg, transparent 97%, $color 0);
  background-repeat: no-repeat;
  transition: background-size 0.5s ease;

  @if $defaultActive == true {
    background-size: 100% 100%;
  } @else {
    background-size: 0 100%;
  }
}

@mixin link-hover-hover($defaultActive: false) {
  @if $defaultActive == true {
    animation: text 800ms forwards;

    @keyframes text {
      0% {
        background-size: 0 100%;
      }

      100% {
        background-size: 100% 100%;
      }
    }
  } @else {
    background-size: 100% 100%;
  }
}

// USE: @include link-hover;

//EXAMPLE 1: @include link-hover;
//EXAMPLE 2: @include link-hover('', true, var(--color-background));
//EXAMPLE 3: @include link-hover('span', true, var(--color-background));

@mixin link-hover($selector: '', $defaultActive: false, $color: currentColor) {
  $selector: $selector;

  @if $selector == '' {
    @include link-hover-default($defaultActive, $color);
  } @else {
    #{$selector} {
      @include link-hover-default($defaultActive, $color);
    }
  }

  &:hover,
  &:focus-visible {
    @if $selector == '' {
      @include link-hover-hover($defaultActive);
    } @else {
      #{$selector} {
        @include link-hover-hover($defaultActive);
      }
    }
  }
}
