@use 'scss_functions/tools.pxtorem' as *;

select {
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none; // hide arrow for chrome
  -moz-appearance: none; // hide arrow for firefox
}

select::-ms-expand {
  display: none;
}

select:focus-visible {
  outline: pxtorem(2px) dotted var(--color-text);
  outline-offset: pxtorem(2px);
}
