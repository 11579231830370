@use 'scss_functions/tools.pxtorem' as *;

input,
textarea {
  background: none;
  border: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
}

input:focus-visible,
textarea:focus-visible {
  outline: pxtorem(2px) solid currentColor;
  outline-offset: pxtorem(2px);
}
