// Visually hidden (screenreader only)
// USE: @include sr-only;
@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0, 0, 0, 0);
}

// Display when focused (screenreader only)
// USE: @include focus-visible;
@mixin sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

// Undo hiding
// USE: @include visible;
@mixin visible {
  position: static;
  width: auto;
  height: auto;
  margin: inherit;
  padding: inherit;
  overflow: auto;
  clip: auto;
}
