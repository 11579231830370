@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.transition' as *;

.back-to-top__inner {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  padding-right: pxtorem(95px);
  visibility: hidden;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.back-to-top__inner--visible {
  visibility: visible;
  opacity: 1;
}

.back-to-top__inner--active,
.back-to-top__inner--static {
  position: fixed;
  right: 0;
  bottom: pxtorem(20px);
}

.back-to-top__button {
  @include transition;

  display: flex;
  justify-content: center;
  align-items: center;
  width: pxtorem(60px);
  height: pxtorem(60px);
  background-color: var(--color-cyan);
  border-radius: 50%;
}

.back-to-top__button:hover,
.back-to-top__button:focus-visible {
  background-color: var(--color-text);
}

.back-to-top__button:hover .back-to-top__icon,
.back-to-top__button:focus-visible .back-to-top__icon {
  color: var(--color-background);
  transform: scale(1.2);
}

.back-to-top__icon {
  @include transition;

  font-size: pxtorem(30px);
}
