@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.module-space' as *;

.embed {
  @include module-space;

  width: 100%;
}

.embed__header {
  @include container-heading;
}

.embed__header > h2 {
  @include heading-space;
}

.embed__wrapper {
  @include container-large;
}
