@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.gradient' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.module-space' as *;
@use 'scss_mixins/tools.picture' as *;
@use 'scss_mixins/tools.typography' as *;

.teaser-list {
  @include container-max;
  @include module-space('margin');

  position: relative;
}

.teaser-list:not(.teaser-list--has-image) {
  padding-top: pxtorem(30px);
  padding-bottom: pxtorem(30px);
  background-color: var(--color-black);

  @include mq(tablet) {
    padding-top: pxtorem(60px);
    padding-bottom: pxtorem(60px);
  }

  @include mq(desktop) {
    padding-top: pxtorem(100px);
    padding-bottom: pxtorem(100px);
  }
}

.teaser-list--has-image {
  padding-top: pxtorem(30px);
  padding-bottom: pxtorem(80px);

  @include mq(tablet) {
    padding-top: pxtorem(60px);
    padding-bottom: pxtorem(100px);
  }

  @include mq(desktop) {
    padding-top: pxtorem(100px);
    padding-bottom: pxtorem(120px);
  }
}

.teaser-list__header {
  @include container-heading;
}

.teaser-list__header > h2 {
  @include heading-space;
}

.teaser-list__context {
  @include container-large;
}

.teaser-list__item {
  display: grid;
  gap: pxtorem(10px) 0;
  grid-template-rows: 1fr auto;
  padding-top: pxtorem(20px);
  padding-bottom: pxtorem(20px);
  border-top: pxtorem(1px) solid var(--color-text);

  @include mq(tablet) {
    grid-template-columns: 65% auto;
    gap: 0 pxtorem(20px);
    align-items: center;
    padding-top: pxtorem(25px);
    padding-bottom: pxtorem(25px);
  }

  @include mq(desktop) {
    grid-template-columns: 65% 15% auto;
    gap: 0 pxtorem(30px);
    padding-top: pxtorem(35px);
    padding-bottom: pxtorem(35px);
  }

  @include mq(bigscreen) {
    padding-top: pxtorem(45px);
    padding-bottom: pxtorem(45px);
  }
}

.teaser-list__item:last-child {
  border-bottom: pxtorem(1px) solid var(--color-text);
}

.teaser-list__location {
  justify-self: start;

  @include mq(tablet) {
    justify-self: center;
  }
}

.teaser-list__link {
  justify-self: end;
  display: none;

  @include mq(desktop) {
    display: block;
  }
}

.teaser-list__link-text {
  margin-right: pxtorem(5px);
}

.teaser-list__button {
  @include button-space;
}

.teaser-list__button-wrapper {
  display: flex;
  justify-content: flex-start;

  @include mq(tablet) {
    justify-content: center;
  }
}

.teaser-list__picture {
  @include picture-background;
}

.teaser-list__picture::before {
  @include gradient-full;
}

.teaser-list__picture::after {
  @include gradient-full(0.4, var(--color-cyan));
}

.teaser-list__image {
  @include image-background;
}

.teaser-list__figcaption {
  @include container-large;

  position: absolute;
  right: 0;
  bottom: pxtorem(30px);
  z-index: 1;
  text-align: left;

  @include mq(tablet) {
    bottom: pxtorem(40px);
    text-align: right;
  }

  @include mq(desktop) {
    bottom: pxtorem(50px);
  }
}
