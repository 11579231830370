@use 'scss_functions/tools.pxtorem' as *;

a,
abbr {
  color: inherit;
  text-decoration: none;
}

a:focus-visible {
  outline: pxtorem(2px) solid currentColor;
  outline-offset: pxtorem(2px);
}
