@use 'scss_mixins/tools.typography' as *;

.text-small {
  @include text-small;
}

.text-intro {
  @include text-intro;
}

.text-large {
  @include text-large;
}

.text-paragraph {
  @include text-paragraph;
}

.text-quote {
  @include text-quote;
}

.text-copyright {
  @include text-copyright;
}

.text-inaktiv {
  color: var(--color-light-grey);
}
