@use 'scss_mixins/tools.typography' as *;

h1,
h2,
h3,
h4,
h5 {
  &:first-child {
    margin-top: 0;
  }
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}
