//USE: @include reset-button;

@mixin reset-button {
  padding: 0;
  color: #000;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
}
