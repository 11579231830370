@use 'scss_functions/tools.pxtorem' as *;

.form__group--radio .form__label {
  margin-bottom: pxtorem(10px);
}

.radiobox {
  position: relative;
  display: inline-flex;
}

// radio box styles
.radiobox__box {
  position: relative;
  display: inline-block;
  flex: 1 0 auto;
  width: pxtorem(22px);
  height: pxtorem(22px);
  margin-right: pxtorem(10px);
}

.radiobox__box::after {
  content: '';
  position: absolute;
  width: pxtorem(22px);
  height: pxtorem(22px);
  background-color: var(--color-background);
  border: pxtorem(2px) solid var(--color-text);
  border-radius: 50%;
}

// radio checked style
.radiobox__input:checked + .radiobox .radiobox__box::before {
  content: '';
  position: absolute;
  top: pxtorem(5px);
  left: pxtorem(5px);
  z-index: 1;
  width: pxtorem(12px);
  height: pxtorem(12px);
  background-color: var(--color-text);
  border-radius: 50%;
}

// radio focus style
.radiobox__input:focus-visible + .radiobox .radiobox__text {
  outline: pxtorem(2px) dotted var(--color-text);
  outline-offset: pxtorem(2px);
}

.form__group--error .radiobox__input:focus-visible + .radiobox .radiobox__text {
  outline-color: var(--color-error-red);
}

// radio label style
.radiobox__text {
  position: relative;
  line-height: pxtorem(22px);
  word-wrap: break-word;
  hyphens: auto;
  cursor: pointer;
}

// error styles
.form__group--error .radiobox__text {
  color: var(--color-error-red);
}

.radio-group--error .radiobox__input + .radiobox .radiobox__box::before {
  background-color: var(--color-error-red);
}

.form__group--error .radiobox__box::after {
  border-color: var(--color-error-red);
}

.radio-group:not(:first-child) {
  margin-top: pxtorem(10px);
}
