@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.gradient' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.module-space' as *;
@use 'scss_mixins/tools.picture' as *;
@use 'scss_mixins/tools.typography' as *;

.teaser-disturber {
  @include container-max;
  @include module-space('margin');

  position: relative;
}

.teaser-disturber__context {
  @include container-small;
}

.teaser-disturber--has-image .teaser-disturber__context {
  padding-top: pxtorem(100px);
  padding-bottom: pxtorem(100px);

  @include mq(tablet) {
    padding-top: pxtorem(120px);
    padding-bottom: pxtorem(120px);
  }

  @include mq(desktop) {
    padding-top: pxtorem(160px);
    padding-bottom: pxtorem(160px);
  }

  @include mq(bigscreen) {
    padding-top: pxtorem(200px);
    padding-bottom: pxtorem(200px);
  }
}

.teaser-disturber__header {
  max-width: 100%;
}

.teaser-disturber__title {
  @include heading-space-small;
}

.teaser-disturber__text {
  @include text-intro;
}

.teaser-disturber__button {
  @include button-space;
}

.teaser-disturber__picture {
  @include picture-background;
}

.teaser-disturber__picture::before {
  @include gradient-full;
}

.teaser-disturber__picture::after {
  @include gradient-full(0.4, var(--color-cyan));
}

.teaser-disturber__image {
  @include image-background;
}

.teaser-disturber__figcaption {
  @include container-large;

  position: absolute;
  right: 0;
  bottom: pxtorem(30px);
  z-index: 1;
  text-align: left;

  @include mq(tablet) {
    bottom: pxtorem(40px);
    text-align: right;
  }

  @include mq(desktop) {
    bottom: pxtorem(50px);
  }
}
