@use 'scss_settings/settings.fonts' as *;
@use 'scss_functions/tools.pxtorem' as *;
@use 'scss_mixins/tools.media-query' as *;

// font

// $family = $font-family-america or $font-family-tobias
// $weight = $font-weight-thin, $font-weight-light, $font-weight-regular
// $size = in px
// $line-height = in px

@mixin font($family, $weight: normal, $size: inherit, $lineHeight: 1.15) {
  font-weight: $weight;
  font-size: pxtorem($size);
  font-family: $family;
  line-height: lineheight(strip-unit($size), $lineHeight);
}

//USE: @include h1;
@mixin h1 {
  @include font($font-family-america, $font-weight-thin, 34px);

  word-wrap: break-word;
  hyphens: auto;

  @include mq(tablet) {
    font-size: pxtorem(65px);
    word-wrap: normal;
    hyphens: none;
  }

  @include mq(desktop) {
    font-size: pxtorem(80px);
  }

  @include mq(bigscreen) {
    font-size: pxtorem(80px);
  }
}

//USE: @include h2;
@mixin h2 {
  @include font($font-family-america, $font-weight-thin, 30px);

  word-wrap: break-word;
  hyphens: auto;

  @include mq(tablet) {
    font-size: pxtorem(40px);
    word-wrap: normal;
    hyphens: none;
  }

  @include mq(desktop) {
    font-size: pxtorem(65px);
  }

  @include mq(bigscreen) {
    font-size: pxtorem(65px);
  }
}

//USE: @include h3;
@mixin h3 {
  @include font($font-family-america, $font-weight-thin, 24px);

  word-wrap: break-word;
  hyphens: auto;

  @include mq(tablet) {
    font-size: pxtorem(30px);
    word-wrap: normal;
    hyphens: none;
  }

  @include mq(desktop) {
    font-size: pxtorem(40px);
  }

  @include mq(bigscreen) {
    font-size: pxtorem(40px);
  }
}

//USE: @include text-small;
@mixin text-small {
  @include font($font-family-america, $font-weight-thin, 12px);

  @include mq(tablet) {
    font-size: pxtorem(14px);
  }

  @include mq(desktop) {
    font-size: pxtorem(16px);
  }

  @include mq(bigscreen) {
    font-size: pxtorem(16px);
  }
}

//USE: @include text-intro;
@mixin text-intro {
  @include font($font-family-america, $font-weight-thin, 18px);

  @include mq(tablet) {
    font-size: pxtorem(20px);
  }

  @include mq(desktop) {
    font-size: pxtorem(26px);
  }

  @include mq(bigscreen) {
    font-size: pxtorem(26px);
  }
}

//USE: @include text-large;
@mixin text-large {
  @include font($font-family-america, $font-weight-thin, 16px);

  @include mq(tablet) {
    font-size: pxtorem(18px);
  }

  @include mq(desktop) {
    font-size: pxtorem(22px);
  }

  @include mq(bigscreen) {
    font-size: pxtorem(22px);
  }
}

//USE: @include text-paragraph;
@mixin text-paragraph {
  @include font($font-family-america, $font-weight-thin, 16px);

  @include mq(tablet) {
    font-size: pxtorem(16px);
  }

  @include mq(desktop) {
    font-size: pxtorem(18px);
  }

  @include mq(bigscreen) {
    font-size: pxtorem(18px);
  }
}

//USE: @include text-quote;
@mixin text-quote {
  @include font($font-family-tobias, $font-weight-light, 26px);

  word-wrap: break-word;
  hyphens: auto;

  @include mq(tablet) {
    font-size: pxtorem(28px);
    word-wrap: normal;
    hyphens: none;
  }

  @include mq(desktop) {
    font-size: pxtorem(38px);
  }

  @include mq(bigscreen) {
    font-size: pxtorem(44px);
  }
}

//USE: @include text-copyright;
@mixin text-copyright {
  @include font($font-family-america, $font-weight-light, 10px);

  @include mq(tablet) {
    font-size: pxtorem(12px);
  }
}
