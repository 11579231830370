@use 'scss_mixins/tools.media-query' as *;
@use 'scss_functions/tools.pxtorem' as *;

//EXAMPLE: see container.njk

//USE: @include container-max;
@mixin container-max {
  width: 100%;
  max-width: pxtorem(1920px);
  margin-right: auto;
  margin-left: auto;
}

//USE: @include container-large;
@mixin container-large {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;

  @include mq(tablet) {
    padding-right: pxtorem(50px);
    padding-left: pxtorem(50px);
  }

  @include mq(desktop) {
    padding-right: pxtorem(100px);
    padding-left: pxtorem(100px);
  }

  @include mq(bigscreen) {
    max-width: pxtorem(1620px) + 2 * pxtorem(150px);
    padding-right: pxtorem(150px);
    padding-left: pxtorem(150px);
  }
}

//USE: @include container-heading
@mixin container-heading {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;

  @include mq(tablet) {
    padding-right: pxtorem(50px);
    padding-left: pxtorem(50px);
  }

  @include mq(desktop) {
    padding-right: pxtorem(315px);
    padding-left: pxtorem(100px);
  }

  @include mq(bigscreen) {
    max-width: pxtorem(1620px) + pxtorem(150px) + pxtorem(430px);
    padding-right: pxtorem(430px);
    padding-left: pxtorem(150px);
  }
}

//USE: @include container-small;
@mixin container-small {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;

  @include mq(tablet) {
    padding-right: pxtorem(50px);
    padding-left: pxtorem(50px);
  }

  @include mq(desktop) {
    max-width: pxtorem(815px) + 2 * pxtorem(150px);
    padding-right: pxtorem(150px);
    padding-left: pxtorem(150px);
  }

  @include mq(bigscreen) {
    max-width: pxtorem(1060px) + 2 * pxtorem(250px);
    padding-right: pxtorem(250px);
    padding-left: pxtorem(250px);
  }
}
