@use 'scss_settings/settings.fonts' as *;
@use 'scss_settings/settings.paths' as *;

@font-face {
  font-weight: normal;
  font-family: $icon-family;
  font-style: normal;
  font-display: swap;
  src: url('#{$path-icon}icomoon.woff2?mlu9zb') format('woff2'),
    url('#{$path-icon}icomoon.woff?mlu9zb') format('woff');
}

.icon {
  // use !important to prevent issues with browser extensions that change fonts
  font-weight: normal;
  font-family: $icon-family !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;

  /* speak: none; */

  // Better Font Rendering ===========
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icons: (
  lightbox-close: '\e912',
  lightbox-fullscreen: '\e915',
  lightbox-zoom-in: '\e916',
  lightbox-zoom-out: '\e917',

  chevron-left: '\e90b',
  chevron-right: '\e90c',

  video-pause: '\e913',
  video-play: '\e90d',

  arrow-up: '\e909',
  arrow-down: '\e908',
  arrow-right: '\e90a',
  arrow-double: '\e906',

  menu-close: '\e914',
  menu-burger: '\e904',
  menu-minus: '\e90f',
  menu-plus: '\e910',

  close: '\e90e',
  quote: '\e905',
  location: '\e907',

  social-facebook: '\e900',
  social-instagram: '\e901',
  social-linkedin: '\e902',
  social-twitter: '\e903',
);

@each $name, $glyph in $icons {
  .icon--#{$name}::before {
    content: $glyph;
  }
}
