@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.gradient' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.module-space' as *;
@use 'scss_mixins/tools.picture' as *;
@use 'scss_mixins/tools.typography' as *;

.footer {
  @include module-space('margin');
  @include container-max;

  position: relative;
  overflow: hidden;
}

.footer__inner {
  @include container-large;

  padding-top: pxtorem(10px);
  padding-bottom: pxtorem(80px);

  @include mq(tablet) {
    padding-bottom: pxtorem(100px);
  }

  @include mq(desktop) {
    padding-bottom: pxtorem(120px);
  }

  @include mq(bigscreen) {
    padding-bottom: pxtorem(120px);
  }
}

.footer--has-image .footer__inner {
  padding-top: pxtorem(100px);
  padding-bottom: pxtorem(100px);

  @include mq(tablet) {
    padding-top: pxtorem(160px);
    padding-bottom: pxtorem(160px);
  }

  @include mq(desktop) {
    padding-top: pxtorem(180px);
    padding-bottom: pxtorem(180px);
  }

  @include mq(bigscreen) {
    padding-top: pxtorem(220px);
    padding-bottom: pxtorem(220px);
  }
}

.footer-socialmedia__items {
  display: flex;
  gap: pxtorem(25px) pxtorem(50px);
  flex-wrap: wrap;
  justify-content: center;

  @include mq(tablet) {
    gap: pxtorem(50px) pxtorem(100px);
  }

  @include mq(desktop) {
    gap: pxtorem(50px) pxtorem(100px);
  }

  @include mq(bigscreen) {
    gap: pxtorem(90px) pxtorem(160px);
  }
}

.footer-socialmedia__link {
  display: block;
  transition: transform 200ms ease-in;
}

.footer-socialmedia__link:hover,
.footer-socialmedia__link:focus-visible {
  transform: scale(1.1);
}

.footer-socialmedia__icon {
  font-size: pxtorem(32px);

  @include mq(desktop) {
    font-size: pxtorem(40px);
  }

  @include mq(bigscreen) {
    font-size: pxtorem(60px);
  }
}

.footer-navigation {
  margin-top: pxtorem(40px);

  @include mq(desktop) {
    margin-top: pxtorem(50px);
  }

  @include mq(bigscreen) {
    margin-top: pxtorem(60px);
  }
}

.footer-navigation__items {
  display: flex;
  gap: pxtorem(12px) pxtorem(25px);
  flex-wrap: wrap;
  justify-content: center;

  @include mq(tablet) {
    gap: pxtorem(20px) pxtorem(40px);
  }

  @include mq(desktop) {
    gap: pxtorem(25px) pxtorem(50px);
  }

  @include mq(bigscreen) {
    gap: pxtorem(50px) pxtorem(100px);
  }
}

.footer__copyright {
  margin-top: pxtorem(20px);
  text-align: center;

  @include mq(desktop) {
    margin-top: pxtorem(30px);
  }

  @include mq(bigscreen) {
    margin-top: pxtorem(40px);
  }
}

.footer__picture {
  @include picture-background;
}

.footer__picture::before {
  @include gradient-to-top;
}

.footer__image {
  @include image-background;
}
