@use './plyr-settings' as *;
@use '../../../../../node_modules/plyr/src/sass/plyr.scss' as *;
@use '../../../../shared/scss/0-settings/settings.colors' as *;
@use '../../../../shared/scss/0-settings/settings.breakpoints' as *;
@use 'scss_functions/tools.pxtorem' as *;
@use 'scss_mixins/tools.typography' as *;
@use 'scss_mixins/tools.media-query' as *;

// ------------------------ video & audio stylings
.plyr--video,
.plyr--audio {
  input[type='range']::-webkit-slider-thumb {
    visibility: hidden;
  }

  input[type='range']::-moz-range-thumb {
    visibility: hidden;
  }

  input[type='range']::-ms-thumb {
    visibility: hidden;
  }

  .plyr__control.plyr__tab-focus,
  .plyr__control:focus-visible,
  input[type='range']:focus-visible {
    outline: 2px solid white;
  }
}

// ------------------------ video
.plyr--video {
  // black overlay, when video is pausing
  &.plyr--stopped {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.5;
    }
  }

  .plyr__control.plyr__tab-focus,
  .plyr__control:hover,
  .plyr__control[aria-expanded='true'] {
    color: var(--color-white);
    background-color: transparent;
  }

  input[type='range'] {
    color: white;
  }
}

.plyr__video-wrapper.plyr__video-embed {
  aspect-ratio: 16 / 9 !important;
}
