@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.module-space' as *;

.text-icon {
  @include module-space('margin');
}

.text-icon__header {
  @include container-heading;
  @include heading-space;
}

.text-icon__header > h2:not(:only-child) {
  @include heading-space-small;
}

.text-icon__intro {
  @include mq(tablet) {
    margin-right: pxtorem(120px);
  }

  @include mq(desktop) {
    max-width: pxtorem(815px);
    margin-right: 0;
  }

  @include mq(bigscreen) {
    max-width: pxtorem(1060px);
  }
}

.text-icon__grid {
  @include container-large;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: pxtorem(50px);

  @include mq(tablet) {
    row-gap: pxtorem(60px);
  }

  @include mq(desktop) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: pxtorem(115px);
    row-gap: pxtorem(80px);
  }

  @include mq(bigscreen) {
    column-gap: pxtorem(180px);
    row-gap: pxtorem(100px);
  }
}

.text-icon__article {
  display: grid;
  grid-template-columns: pxtorem(55px) auto;
  column-gap: pxtorem(20px);

  @include mq(tablet) {
    padding-right: pxtorem(75px);
    padding-left: pxtorem(75px);
    grid-template-columns: pxtorem(80px) auto;
    column-gap: pxtorem(40px);
  }

  @include mq(desktop) {
    padding-right: 0;
    padding-left: 0;
    grid-template-columns: pxtorem(120px) auto;
  }

  @include mq(bigscreen) {
    grid-template-columns: pxtorem(140px) auto;
    column-gap: pxtorem(60px);
  }
}

.text-icon__text {
  margin-top: pxtorem(10px);

  @include mq(desktop) {
    margin-top: pxtorem(20px);
  }
}

.text-icon__icon {
  width: 100%;
  height: 100%;
}
