@use 'scss_functions/tools.pxtorem' as *;

.form__group--input .form__field {
  position: relative;
}

.form__input {
  position: relative;
  z-index: 3;
  display: block;
  width: 100%;
  height: pxtorem(40px);
  margin-top: pxtorem(10px);
  padding: pxtorem(8px);
  color: var(--color-text);
  border-bottom: pxtorem(2px) solid var(--color-text);
}

// error styles
.form__group--input.form__group--error .form__input {
  color: var(--color-error-red);
  border-color: var(--color-error-red);
  outline-color: var(--color-error-red);
}
