@use 'scss_functions/tools.pxtorem' as *;

.form__group--checkbox .form__label {
  margin-bottom: pxtorem(10px);
}

.checkbox {
  position: relative;
  display: inline-flex;
}

.checkbox__box {
  position: relative;
  display: inline-block;
  flex: 0 0 auto; // prevent wrong checkbox width if label text gets to long
  width: pxtorem(20px);
  height: pxtorem(20px);
  margin-right: pxtorem(10px);
  background-color: var(--color-background);
  border: pxtorem(2px) solid var(--color-text);
}

.checkbox__text {
  position: relative;
  line-height: pxtorem(22px);
  word-wrap: break-word;
  hyphens: auto;
  cursor: pointer;
}

// checkbox checked style
.checkbox__input:checked + .checkbox .checkbox__box::before {
  content: '';
  position: absolute;
  top: pxtorem(1px);
  left: pxtorem(5px);
  z-index: 1;
  display: inline-block;
  width: pxtorem(8px);
  height: pxtorem(12px);
  border: solid var(--color-text);
  border-width: 0 2px 2px 0;
  transform: rotate(34deg);
}

// checkbox focus style
.checkbox__input:focus-visible + .checkbox .checkbox__text-wrapper {
  outline: pxtorem(2px) dotted var(--color-text);
  outline-offset: pxtorem(2px);
}

.form__group--error
  .checkbox__input:focus-visible
  + .checkbox
  .checkbox__text-wrapper {
  outline-color: var(--color-error-red);
}

// search checkbox modifier styles ----------------------------------------
.checkbox--search {
  display: flex;
  align-items: center;
}

.checkbox__box--search {
  width: pxtorem(13px);
  height: pxtorem(13px);
}

// checkbox search checked style
.checkbox__input--search:checked {
  & + .checkbox--search .checkbox__box--search {
    background-color: var(--color-text);
  }

  & + .checkbox--search .checkbox__box--search::before {
    display: none;
  }
}

// error styles ---------------------------------------------
.form__group--error .checkbox__text {
  color: var(--color-error-red);
}

.checkbox-group--error .checkbox__input + .checkbox .checkbox__box::before {
  border-color: var(--color-error-red);
}

.form__group--error .checkbox__box {
  border-color: var(--color-error-red);
}

.checkbox-group:not(:first-child) {
  margin-top: pxtorem(10px);
}
