@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.media-query' as *;

.cookiebanner {
  /* CSS custom properties defined in @3pc/cookiebanner/style.cookiebanner.scss */
  --cookiebanner-root-size: 1em;
  --cookiebanner-background-color-overlay: rgba(0, 0, 0, 0.7);
  --cookiebanner-background-color-panel: #fff;
  --cookiebanner-max-width: 1100px;
  --cookiebanner-font-size-text: 0.9em;
  --cookiebanner-font-size-controls: 1em;
  --cookiebanner-font-size-toggler-text: 0.6em;
  --cookiebanner-font-size-heading: 2em;
  --cookiebanner-font-weight-highlight: 200;
  --cookiebanner-color-text: #333;
  --cookiebanner-color-controls: #333;
  --cookiebanner-color-controls-active: #333;
  --cookiebanner-color-controls-disabled: #666;
}

.cookiebanner.cookiebanner--tablist .cookiebanner__cookie-section {
  min-height: auto;
}

// button button--tertiary

.cookiebanner .cookiebanner__buttons button {
  display: inline-block;
  padding: pxtorem(10px) pxtorem(24px);
  color: var(--color-background);
  border: pxtorem(1px) solid var(--color-background);
  border-radius: pxtorem(25px);
  backdrop-filter: blur(4px);

  @include mq(desktop) {
    padding: pxtorem(12px) pxtorem(30px);
  }
}

.cookiebanner .cookiebanner__buttons button:hover,
.cookiebanner .cookiebanner__buttons button:focus-visible {
  color: var(--color-text);
  background-color: var(--color-background);
  border: pxtorem(1px) solid var(--color-text);
}

.cookiebanner input:focus-visible + label {
  outline: pxtorem(2px) solid currentColor;
  outline-offset: pxtorem(2px);
}
