@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.module-space' as *;

.error__logo {
  @include container-large;

  display: inline-block;
  margin-top: pxtorem(20px);

  @include mq(tablet) {
    margin-top: pxtorem(40px);
  }

  @include mq(bigscreen) {
    margin-top: pxtorem(50px);
  }
}

.error__logo > svg {
  width: pxtorem(180px);
  height: pxtorem(50px);

  @include mq(tablet) {
    width: pxtorem(220px);
    height: pxtorem(60px);
  }

  @include mq(desktop) {
    width: pxtorem(240px);
    height: pxtorem(70px);
  }

  @include mq(bigscreen) {
    width: pxtorem(280px);
    height: pxtorem(77px);
  }
}

.error__logo > svg path {
  fill: var(--color-text);
}

.error__inner {
  @include container-small;
  @include module-space;
}

.error__header h1 {
  margin-bottom: pxtorem(30px);

  @include mq(tablet) {
    margin-bottom: pxtorem(40px);
  }

  @include mq(desktop) {
    margin-bottom: pxtorem(50px);
  }
}

.error__header h2 {
  @include heading-space-small;
}

.error__button {
  @include button-space;
}
