@use 'scss_mixins/tools.media-query' as *;
@use 'scss_functions/tools.pxtorem' as *;

//USE: @include module-space;

//EXAMPLE 1: @include module-space;
//EXAMPLE 2: @include module-space('margin');
//EXAMPLE 3: @include module-space('padding');

@mixin module-space($spacetype: 'margin') {
  @if $spacetype == 'margin' {
    margin-top: pxtorem(80px);

    @include mq(tablet) {
      margin-top: pxtorem(100px);
    }

    @include mq(desktop) {
      margin-top: pxtorem(120px);
    }

    @include mq(bigscreen) {
      margin-top: pxtorem(120px);
    }
  }

  @if $spacetype == 'padding' {
    padding-top: pxtorem(80px);

    @include mq(tablet) {
      padding-top: pxtorem(100px);
    }

    @include mq(desktop) {
      padding-top: pxtorem(120px);
    }

    @include mq(bigscreen) {
      padding-top: pxtorem(120px);
    }
  }
}

//USE: @include module-space-small;

@mixin module-space-small {
  margin-top: pxtorem(40px);

  @include mq(tablet) {
    margin-top: pxtorem(60px);
  }

  @include mq(desktop) {
    margin-top: pxtorem(80px);
  }

  @include mq(bigscreen) {
    margin-top: pxtorem(80px);
  }
}

//USE: @include heading-space;

@mixin heading-space {
  margin-bottom: pxtorem(30px);

  @include mq(tablet) {
    margin-bottom: pxtorem(40px);
  }

  @include mq(desktop) {
    margin-bottom: pxtorem(50px);
  }
}

//USE: @include heading-space-small;

@mixin heading-space-small {
  margin-bottom: pxtorem(15px);

  @include mq(tablet) {
    margin-bottom: pxtorem(20px);
  }

  @include mq(desktop) {
    margin-bottom: pxtorem(30px);
  }
}

//USE: @include button-space;

@mixin button-space {
  margin-top: pxtorem(30px);

  @include mq(tablet) {
    margin-top: pxtorem(40px);
  }

  @include mq(desktop) {
    margin-top: pxtorem(50px);
  }

  @include mq(bigscreen) {
    margin-top: pxtorem(50px);
  }
}

//USE: @include button-space-small;

@mixin button-space-small {
  margin-top: pxtorem(10px);

  @include mq(tablet) {
    margin-top: pxtorem(20px);
  }

  @include mq(desktop) {
    margin-top: pxtorem(30px);
  }

  @include mq(bigscreen) {
    margin-top: pxtorem(30px);
  }
}
