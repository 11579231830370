@use '../../../scss/0-settings/settings.colors' as *;

$pswp__show-hide-transition-duration: 333ms !default;
$pswp__controls-transition-duration: 333ms !default;
$pswp__background-color: var(--color-black) !default;
$pswp__text-color: var(--color-white) !default;
$pswp__placeholder-color: #313439 !default;
$pswp__box-sizing-border-box: false !default; // disable .pswp * { box-sizing:border-box } (in case you already have it in your site css)
$pswp__root-z-index: 20 !default;
$pswp__skin-filename: 'default-skin';
$pswp__error-text-color: #888 !default; // "Image not loaded" text color
$pswp__include-minimal-style: true !default;
