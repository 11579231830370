@media print {
  @page {
    margin: 0.5cm;
  }

  *,
  *:before,
  *:after {
    color: #000;
    background: #fff none !important;
    box-shadow: none;
  }

  /* set font */
  body {
    color: #000 !important;
    font-size: 1rem;
  }

  /* basic font */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000;
    font-weight: 400;
  }

  h1 {
    font-size: 200%;
  }

  h2 {
    font-size: 150%;
  }

  h3 {
    font-size: 115%;
  }

  h4 {
    font-size: 100%;
  }

  h5 {
    font-size: 100%;
  }

  h6 {
    font-size: 100%;
  }

  p {
    margin: 0;
    font-size: 12pt;
  }

  /* basic links */
  a:link,
  a:visited {
    color: #000 !important;
    text-decoration: underline;

    &:after {
      content: ' (' attr(href) ')';
    }
  }

  .noprint {
    display: none !important;
  }
}
