@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.gradient' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.module-space' as *;
@use 'scss_mixins/tools.transition' as *;
@use 'scss_mixins/tools.typography' as *;

// textmedia: (global)

.textmedia {
  @include module-space;
}

.textmedia + .textmedia {
  @include module-space-small;
}

.page-header + .textmedia {
  margin-top: 0;
}

// textmedia: (textblock)

.textmedia__header h1 {
  margin-bottom: pxtorem(15px);

  @include mq(tablet) {
    margin-bottom: pxtorem(20px);
  }

  @include mq(desktop) {
    margin-bottom: pxtorem(30px);
  }
}

.textmedia__header h2 {
  @include heading-space-small;

  margin-top: pxtorem(30px);

  @include mq(tablet) {
    margin-top: pxtorem(40px);
  }

  @include mq(desktop) {
    margin-top: pxtorem(50px);
  }
}

.textmedia__textblock h3 {
  margin-top: pxtorem(30px);
  margin-bottom: pxtorem(8px);

  @include mq(tablet) {
    margin-top: pxtorem(40px);
  }

  @include mq(desktop) {
    margin-top: pxtorem(50px);
  }
}

.textmedia__textblock p,
.textmedia__textblock ul,
.textmedia__textblock ol {
  margin-bottom: pxtorem(15px);

  @include mq(desktop) {
    margin-bottom: pxtorem(20px);
  }

  @include mq(bigscreen) {
    margin-bottom: pxtorem(25px);
  }
}

// first paragraph or list in textblock should not have margin-top

.textmedia__textblock h3 + p,
.textmedia__textblock h3 + ul,
.textmedia__textblock h3 + ol,
.textmedia__textblock p:first-child,
.textmedia__textblock ul:first-child,
.textmedia__textblock ol:first-child {
  margin-top: 0;
}

// last paragraph or list in textblock should not have margin-bottom

.textmedia__textblock p:last-child,
.textmedia__textblock ul:last-child,
.textmedia__textblock ol:last-child {
  margin-bottom: 0;
}

// first heading in textblock should not have space top

.textmedia__header > h1:first-child,
.textmedia__header > h2:first-child,
.textmedia__textblock > h3:first-child {
  margin-top: 0 !important;
}

// textmedia: hasHeader

.textmedia__header {
  @include container-heading;

  margin-bottom: pxtorem(30px);

  @include mq(tablet) {
    margin-bottom: pxtorem(40px);
  }

  @include mq(desktop) {
    margin-bottom: pxtorem(50px);
  }
}

.textmedia__intro {
  @include text-intro;

  margin-top: pxtorem(15px);

  @include mq(tablet) {
    margin-top: pxtorem(20px);
    margin-right: pxtorem(120px);
  }

  @include mq(desktop) {
    max-width: pxtorem(815px);
    margin-top: pxtorem(30px);
    margin-right: 0;
  }

  @include mq(bigscreen) {
    max-width: pxtorem(1060px);
  }
}

// textmedia: text only

.textmedia--text-only .textmedia__grid {
  @include container-small;
}

// textmedia: text column

.textmedia--text-column .textmedia__grid {
  @include container-large;

  display: grid;
  grid-template-columns: 1fr;
  gap: pxtorem(30px);

  @include mq(tablet) {
    gap: pxtorem(40px);
    grid-template-columns: 1fr 1fr;
  }

  @include mq(desktop) {
    gap: pxtorem(50px);
  }

  @include mq(bigscreen) {
    gap: pxtorem(60px);
  }
}

// textmedia: image

.textmedia__figure {
  position: relative;
}

.textmedia__figure:not(:last-child) {
  margin-bottom: pxtorem(30px);

  @include mq(tablet) {
    margin-bottom: pxtorem(40px);
  }

  @include mq(desktop) {
    margin-bottom: pxtorem(50px);
  }
}

.textmedia__picture::after {
  @include gradient-full;
}

// textmedia: image with lightbox

.textmedia__lightbox-toggle {
  position: relative;
}

.textmedia__lightbox-icon {
  @include transition;

  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: inline-flex;
  padding: pxtorem(5px);
  color: var(--color-text);
  font-size: pxtorem(20px);
  background-color: var(--color-background);
  cursor: pointer;
  opacity: 0.6;

  @include mq(desktop) {
    font-size: pxtorem(30px);
  }
}

.textmedia__figure:hover .textmedia__lightbox-icon {
  opacity: 1;
}

// textmedia: image with caption

.textmedia__caption {
  @include text-small;

  display: inline-block;
  margin-top: pxtorem(8px);
  text-transform: uppercase;

  @include mq(desktop) {
    margin-top: pxtorem(10px);
  }
}

.textmedia__copyright {
  margin-top: pxtorem(5px);
}

// textmedia: image left & image right

.textmedia--image-left .textmedia__inner,
.textmedia--image-right .textmedia__inner {
  @include container-large;

  display: grid;
  grid-template-columns: 1fr;
  gap: pxtorem(30px);

  @include mq(tablet) {
    grid-template-columns: 1fr 1fr;
    gap: pxtorem(40px);
  }

  @include mq(desktop) {
    gap: pxtorem(50px);
  }

  @include mq(bigscreen) {
    gap: pxtorem(60px);
  }
}

.textmedia--image-left .textmedia__images {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.textmedia--image-left .textmedia__figure:not(:last-child) {
  margin-bottom: pxtorem(30px);

  @include mq(tablet) {
    margin-bottom: pxtorem(40px);
  }

  @include mq(desktop) {
    margin-bottom: pxtorem(50px);
  }
}

.textmedia--image-right .textmedia__figure:not(:first-child) {
  margin-top: pxtorem(30px);

  @include mq(tablet) {
    margin-top: pxtorem(40px);
  }

  @include mq(desktop) {
    margin-top: pxtorem(50px);
  }
}

// textmedia: image below

.textmedia--image-below .textmedia__inner {
  @include container-large;

  display: grid;
  grid-template-columns: 1fr;
  gap: pxtorem(30px);

  @include mq(tablet) {
    gap: pxtorem(40px);
  }

  @include mq(desktop) {
    gap: pxtorem(50px);
  }

  @include mq(bigscreen) {
    gap: pxtorem(60px);
  }
}

.textmedia--image-below .textmedia__figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textmedia--image-below .textmedia__figcaption {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// textmedia: image only

.textmedia--image-only .textmedia__images {
  @include container-small;
}

.textmedia--image-only .textmedia__figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textmedia--image-only .textmedia__figcaption {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// textmedia: text raw

.textmedia.textmedia--raw {
  max-width: 100%;
  margin-top: 0;
  padding-right: 0;
  padding-left: 0;
}

.textmedia.textmedia--raw .textmedia__header,
.textmedia.textmedia--raw .textmedia__grid {
  max-width: 100%;
  padding-right: 0;
  padding-left: 0;
}
