@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.transition' as *;
@use 'scss_mixins/tools.typography' as *;

.button {
  @include text-paragraph;
  @include transition;

  display: inline-block;
  padding: pxtorem(10px) pxtorem(24px);
  border-radius: pxtorem(25px);
  backdrop-filter: blur(4px);

  @include mq(desktop) {
    padding: pxtorem(12px) pxtorem(30px);
  }
}

.button--primary {
  color: var(--color-text);
  border: pxtorem(1px) solid var(--color-text);
}

.button--secondary {
  color: var(--color-text);
  background-color: var(--color-cyan);
  border: pxtorem(1px) solid var(--color-cyan);
}

.button--tertiary {
  color: var(--color-background);
  background-color: var(--color-text);
  border: pxtorem(1px) solid var(--color-background);
}

.button--primary:hover,
.button--primary:focus-visible,
.button--secondary:hover,
.button--secondary:focus-visible {
  color: var(--color-background);
  background-color: var(--color-text);
  border-color: var(--color-text);
}

.button--tertiary:hover,
.button--tertiary:focus-visible {
  color: var(--color-text);
  background-color: var(--color-background);
  border-color: var(--color-text);
}
