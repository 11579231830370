// USE: @include picture-background
// to set a picture as background
@mixin picture-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-position: center;
}

// USE: @include image-background
// to set a image as background cover
@mixin image-background {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
