@use 'scss_functions/tools.pxtorem' as *;
@use 'scss_mixins/tools.link-hover' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.transition' as *;
@use 'scss_mixins/tools.typography' as *;

.pagination {
  display: flex;
  justify-content: center;
}

.pagination__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.pagination__item {
  @include text-large;

  margin-right: pxtorem(10px);
  margin-left: pxtorem(10px);

  @include mq(desktop) {
    margin-right: pxtorem(15px);
    margin-left: pxtorem(15px);
  }
}

.pagination__item a:not(.pagination__button) {
  display: block;
}

.pagination__item--first {
  margin-right: pxtorem(10px);
  margin-left: 0;

  @include mq(desktop) {
    margin-right: pxtorem(15px);
  }
}

.pagination__item--last {
  margin-right: 0;
  margin-left: pxtorem(10px);

  @include mq(desktop) {
    margin-left: pxtorem(15px);
  }
}

.pagination__item--current {
  @include link-hover('', true, var(--color-background));
}

.pagination__button {
  @include transition;

  display: block;
  font-size: pxtorem(20px);

  @include mq(desktop) {
    font-size: pxtorem(30px);
  }
}

.pagination__button:hover,
.pagination__button:focus-visible {
  transform: scale(1.2);
}

.pagination__button--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
