@use 'scss_functions/tools.pxtorem' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_settings/settings.fonts' as *;

@use './formfields--radio';
@use './formfields--checkbox';
@use './formfields--select';
@use './formfields--input';
@use './formfields--textarea';

.form__group {
  position: relative;
  margin-top: pxtorem(15px);

  @include mq(tablet) {
    margin-top: pxtorem(25px);
  }
}

.form__label {
  display: block;
}

.form__group--error .form__label {
  color: var(--color-error-red);
}

.form__error-text {
  margin-top: pxtorem(5px);
  color: var(--color-error-red);
}
