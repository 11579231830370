@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.typography' as *;

.global-header {
  width: 100%;
}

// TOP NAVIGATION

.global-header__top {
  @include container-large;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: pxtorem(10px);
  padding-bottom: pxtorem(10px);
  color: var(--color-text);
  background-color: var(--color-black);

  @include mq(tablet) {
    align-items: flex-end;
    padding-top: pxtorem(15px);
    padding-bottom: pxtorem(15px);
  }

  @include mq(desktop) {
    padding-top: pxtorem(20px);
    padding-bottom: pxtorem(20px);
  }
}

.global-header--is-light .global-header__top {
  color: var(--color-background);
  background-color: var(--color-text);
}

// meta-navigation

.global-header__meta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: pxtorem(15px);

  @include mq(desktop) {
    gap: pxtorem(20px);
  }

  @include mq(bigscreen) {
    gap: pxtorem(25px);
  }
}

.global-header__logo {
  display: inline-block;
}

.global-header__logo > svg {
  width: pxtorem(180px);
  height: pxtorem(50px);

  @include mq(tablet) {
    width: pxtorem(220px);
    height: pxtorem(60px);
  }

  @include mq(desktop) {
    width: pxtorem(240px);
    height: pxtorem(70px);
  }

  @include mq(bigscreen) {
    width: pxtorem(280px);
    height: pxtorem(77px);
  }
}

.global-header:not(.global-header--is-light) .logo-ubermetrics--titel,
.global-header:not(.global-header--is-light) .logo-ubermetrics--subtitel {
  fill: var(--color-text);
}

.global-header__meta-navigation--top {
  display: none;

  @include mq(tablet) {
    display: block;
  }
}

.global-header__meta-navigation--top .global-header__meta-list {
  @include text-small;

  display: flex;
  gap: pxtorem(20px);
}

.global-header__meta-item-wrapper {
  display: flex;
  gap: pxtorem(15px);
}

.global-header__meta-navigation--bottom {
  display: block;

  @include mq(tablet) {
    display: none;
  }
}

.global-header__meta-navigation--bottom .global-header__meta-list {
  margin-top: pxtorem(30px);
}

.global-header__meta-navigation--bottom .global-header__meta-item {
  margin-top: pxtorem(10px);
}

// burger & close button

.global-header__menu {
  width: pxtorem(40px);
  height: pxtorem(40px);
  color: var(--color-text);
}

.global-header__menu-close {
  display: none;
}

.global-header__menu[aria-expanded='true'] .global-header__menu-close {
  display: flex;
  justify-content: center;
  align-items: center;
}

.global-header__menu[aria-expanded='true'] .global-header__menu-burger {
  display: none;
}

.global-header__menu-burger,
.global-header__menu-close {
  font-size: pxtorem(30px);

  @include mq(tablet) {
    font-size: pxtorem(40px);
  }
}

.global-header--is-light .global-header__menu {
  color: var(--color-background);
}

.global-header--is-light .global-header__menu-burger,
.global-header--is-light .global-header__menu-close {
  color: var(--color-background);
}

// BOTTOM NAVIGATION
// main-navigation

.global-header__bottom {
  @include container-large;

  position: absolute;
  top: pxtorem(60px);
  left: 0;
  z-index: 9;
  color: var(--color-text);
  background-color: var(--color-black);

  @include mq(tablet) {
    top: pxtorem(101px);
  }

  @include mq(desktop) {
    top: pxtorem(118px);
  }

  @include mq(bigscreen) {
    top: pxtorem(123px);
  }
}

.global-header__bottom-inner {
  padding-bottom: pxtorem(30px);

  @include mq(tablet) {
    padding-bottom: pxtorem(50px);
  }

  @include mq(desktop) {
    padding-bottom: pxtorem(70px);
  }
}

.global-header--is-light .global-header__bottom {
  color: var(--color-background);
  background-color: var(--color-text);
}

.global-header__navigation {
  margin-top: pxtorem(30px);

  @include mq(tablet) {
    margin-top: pxtorem(40px);
  }
}

.global-header__navigation-grid {
  display: grid;

  gap: pxtorem(30px);
}

.global-header__navigation-grid--four {
  @include mq(desktop) {
    gap: pxtorem(120px);
    grid-template-columns: repeat(4, 1fr);
  }

  @include mq(bigscreen) {
    gap: pxtorem(140px);
  }
}

.global-header__navigation-grid--five {
  @include mq(desktop) {
    gap: pxtorem(80px);
    grid-template-columns: repeat(5, 1fr);
  }

  @include mq(bigscreen) {
    gap: pxtorem(100px);
  }
}

// main-navigation sublist

.global-header__navigation-sublist-item {
  margin-top: pxtorem(10px);
  padding-bottom: pxtorem(5px);

  @include mq(desktop) {
    margin-top: pxtorem(25px);
  }
}

.global-header__navigation-link {
  @include text-intro;
}

.global-header__navigation-wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
}

// main-navigation button plus & minus

.global-header__navigation-button {
  display: block;
  color: var(--color-text);

  @include mq(desktop) {
    display: none;
  }
}

.global-header--is-light .global-header__navigation-button {
  color: var(--color-background);
}

.global-header--is-light .global-header__navigation--button-plus,
.global-header--is-light .global-header__navigation--button-minus {
  color: var(--color-background);
}

.global-header__navigation--button-minus {
  display: none;
}

.global-header__navigation-button[aria-expanded='true']
  .global-header__navigation--button-minus {
  display: block;
}

.global-header__navigation-button[aria-expanded='true']
  .global-header__navigation--button-plus {
  display: none;
}

.global-header__navigation--button-plus > i,
.global-header__navigation--button-minus > i {
  font-size: pxtorem(20px);

  @include mq(tablet) {
    font-size: pxtorem(30px);
  }
}
