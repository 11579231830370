@mixin fontFace($family, $src, $style: normal, $weight: normal) {
  @font-face {
    font-weight: $weight;
    font-family: $family;
    font-style: $style;
    src: url('#{$src}.woff2') format('woff2'),
      url('#{$src}.woff') format('woff'), url('#{$src}.woff') format('eot');
    font-display: swap;
  }
}
