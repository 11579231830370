@use 'scss_functions/tools.pxtorem' as *;

.form__group--selectfield .form__field {
  position: relative;
}

.form__select {
  position: relative;
  z-index: 3;
  display: block;
  width: 100%;
  height: pxtorem(40px);
  margin-top: pxtorem(10px);
  padding: pxtorem(8px);
  color: var(--color-text);
  border-bottom: pxtorem(2px) solid var(--color-text);
}

.form__group--selectfield .icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 4;
  color: var(--color-text);
  font-size: pxtorem(10px);
  transform: translateY(-50%);
  pointer-events: none;
}

// error styles
.form__group--selectfield.form__group--error .form__select {
  color: var(--color-error-red);
  border-color: var(--color-error-red);
  outline-color: var(--color-error-red);
}

.form__group--selectfield.form__group--error .icon {
  color: var(--color-error-red);
}
