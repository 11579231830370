@use 'scss_settings/settings.fonts' as *;
@use 'scss_settings/settings.colors' as *;
@use 'scss_functions/tools.pxtorem' as *;
@use 'scss_mixins/tools.media-query' as *;

html {
  font-weight: $font-weight-regular;
  font-size: 62.5%;
  font-family: $font-family-america;
  line-height: 1.15;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Pure rgb values allow us to do something like: rgba(var(--black-color-rgb), 0.2)
// --black-color-rgb: 255, 255, 255;
// USE: var(--color-black)
:root {
  // Colors
  @each $color-name, $color-hex in $colors {
    // stylelint-disable custom-property-pattern
    --color-#{$color-name}: #{$color-hex};
    --color-#{$color-name}-rgb: #{hex-to-rgb($color-hex)};
  }

  --color-text: var(--color-white);
  --color-background: var(--color-grey);
}

body {
  color: var(--color-text);
  font-weight: $font-weight-thin;
  font-size: pxtorem(16px);
  background-color: var(--color-background);

  @include mq(desktop) {
    font-size: pxtorem(18px);
  }
}
