@use 'scss_mixins/tools.visibillity' as *;

.sr-only {
  @include sr-only;
}

.is-visible {
  @include visible;
}

.is-hidden {
  display: none;
}
