@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.module-space' as *;
@use 'scss_functions/tools.pxtorem' as *;

.base-container {
  @include module-space('margin');
}

.base-container--small {
  @include container-small;
}

.base-container--large {
  @include container-large;
}

.base-container--heading {
  @include container-heading;
}

.base-container--max {
  @include container-max;
}

.base-container span {
  display: block;
  padding: pxtorem(5px);
}

.base-container div {
  border: pxtorem(2px) solid var(--color-text);
}
