@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.fixed-ratio' as *;
@use 'scss_mixins/tools.gradient' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.module-space' as *;
@use 'scss_mixins/tools.picture' as *;
@use 'scss_mixins/tools.transition' as *;
@use 'scss_mixins/tools.typography' as *;

// !important
// override module-space to module-space-small
// for every component that comes after page-header

.page-header + * {
  margin-top: pxtorem(40px) !important;

  @include mq(tablet) {
    margin-top: pxtorem(60px) !important;
  }

  @include mq(desktop) {
    margin-top: pxtorem(80px) !important;
  }

  @include mq(bigscreen) {
    margin-top: pxtorem(80px) !important;
  }
}

.page-header {
  @include container-max;

  position: relative;
}

.page-header__context {
  @include container-large;

  padding-top: pxtorem(40px);

  @include mq(tablet) {
    padding-top: pxtorem(60px);
  }

  @include mq(desktop) {
    padding-top: pxtorem(80px);
  }
}

.page-header--has-image .page-header__context,
.page-header--has-video .page-header__context {
  padding-top: pxtorem(180px);
  padding-bottom: pxtorem(85px);

  @include mq(tablet) {
    padding-top: pxtorem(200px);
    padding-bottom: pxtorem(95px);
  }

  @include mq(desktop) {
    padding-top: pxtorem(250px);
    padding-bottom: pxtorem(115px);
  }

  @include mq(bigscreen) {
    padding-top: pxtorem(300px);
    padding-bottom: pxtorem(115px);
  }
}

.page-header__tagline,
.page-header__date {
  display: block;
  margin-bottom: pxtorem(5px);

  @include mq(desktop) {
    margin-bottom: pxtorem(10px);
  }
}

.page-header__intro {
  @include text-intro;

  margin-top: pxtorem(10px);

  @include mq(tablet) {
    margin-top: pxtorem(20px);
    margin-right: pxtorem(120px);
  }

  @include mq(desktop) {
    max-width: pxtorem(815px);
    margin-top: pxtorem(30px);
    margin-right: 0;
  }

  @include mq(bigscreen) {
    max-width: pxtorem(1060px);
  }
}

.page-header__button {
  @include button-space;
}

// has Image

.page-header__picture {
  @include picture-background;
}

.page-header__picture::before {
  @include gradient-to-bottom;
}

.page-header__image {
  @include image-background;
}

.page-header__figcaption {
  @include container-large;

  position: absolute;
  right: 0;
  bottom: pxtorem(30px);
  z-index: 1;
  text-align: left;

  @include mq(tablet) {
    text-align: right;
  }

  @include mq(desktop) {
    bottom: pxtorem(50px);
  }
}

// has Video

.page-header__video-figure {
  @include picture-background;
}

.page-header__video-figure::before {
  @include gradient-to-bottom;

  // fix --has-video bug: to show gradient on top of video
  height: calc(100% + 1px);
}

.page-header__video {
  @include image-background;
}

.page-header__button-outer {
  @include container-large;

  position: absolute;
  top: 0;
  width: 100%;
}

.page-header__button-inner {
  position: relative;
}

.page-header__button-play {
  @include transition;

  position: absolute;
  top: pxtorem(30px);
  right: 0;
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: pxtorem(42px);
  height: pxtorem(42px);
  overflow: hidden;
  color: var(--color-text);
  text-decoration: none;
  border: pxtorem(1px) solid var(--color-text);
  border-radius: 100%;
  backdrop-filter: blur(4px);
}

.page-header__button-play:hover,
.page-header__button-play:focus-visible {
  background-color: var(--color-text);
}

// button icon--video-play and icon--video-pause

.page-header__button-play:not(.page-header__button-play--paused)
  .icon--video-pause,
.page-header__button-play--paused .icon--video-play {
  display: none;
}

.page-header__button-play:not(.page-header__button-play--paused)
  .icon--video-play,
.page-header__button-play--paused .icon--video-pause {
  display: block;
}

.page-header__button-play > i {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text);
  font-size: pxtorem(20px);
}

.page-header__button-play:hover > i,
.page-header__button-play:focus-visible > i {
  color: var(--color-background);
}

.page-header__button-play .icon--video-play {
  padding-left: 4px;

  @include mq(tablet) {
    padding-left: 5px;
  }
}
