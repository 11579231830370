$font-family: 'fontTobias', 'Verdana', 'Arial', 'Helvetica', sans-serif;

// headings font family
$font-family-tobias: 'fontTobias';
// text font family
$font-family-america: 'fontAmerica';

$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-regular: 400;

$icon-family: 'icomoon';
$line-height: 1.15;
