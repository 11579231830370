@use 'scss_mixins/tools.gradient' as *;

.picture {
  position: relative;
  display: inline-block;
}

.base__picture-gradient-full::before {
  @include gradient-full;
}

.base__picture--gradient-full--custom::after {
  @include gradient-full(0.4, var(--color-cyan));
}

.base__picture--gradient-to-top::before {
  @include gradient-to-top;
}

.base__picture--gradient-to-bottom::before {
  @include gradient-to-bottom;
}
