@use 'scss_functions/tools.pxtorem' as *;
@use 'scss_mixins/tools.media-query' as *;

ul li,
ol li {
  position: relative;
}

ol {
  counter-reset: list;
}

.list--bullet > li,
.list--alphabet > li,
.list--number > li {
  padding-left: pxtorem(20px);

  @include mq(talet) {
    padding-left: pxtorem(25px);
  }

  @include mq(desktop) {
    padding-left: pxtorem(30px);
  }
}

// Bullet

.list--bullet > li::before {
  content: '';
  position: absolute;
  top: 6px;
  left: 0;
  display: block;
  width: pxtorem(6px);
  height: pxtorem(6px);
  background-color: var(--color-cyan);
  border-radius: 50%;

  @include mq(tablet) {
    top: pxtorem(7px);
  }

  @include mq(desktop) {
    top: pxtorem(8px);
    width: pxtorem(8px);
    height: pxtorem(8px);
  }
}

// Number & Alphabet

.list--alphabet > li::before,
.list--number > li::before {
  position: absolute;
  left: 0;
  color: var(--color-cyan);
  counter-increment: list;
}

.list--alphabet > li::before {
  content: counter(list, lower-alpha) '.';
}

.list--number > li::before {
  content: counter(list) '.';
}
