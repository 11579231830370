@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.gradient' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.module-space' as *;
@use 'scss_mixins/tools.typography' as *;

.teaser-grid {
  @include module-space;
}

.teaser-grid__header {
  @include container-heading;
}

.teaser-grid__header > h2 {
  @include heading-space;
}

.teaser-grid--single .teaser-grid__header > h2 {
  @include mq(tablet) {
    margin-right: pxtorem(120px);
  }

  @include mq(desktop) {
    margin-right: 0;
  }
}

.teaser-grid__inner {
  @include container-max;

  display: grid;

  @include mq(tablet) {
    @include container-large;
  }
}

.teaser-grid--double .teaser-grid__inner {
  grid-template-columns: repeat(1, 1fr);
  row-gap: pxtorem(50px);

  @include mq(tablet) {
    grid-template-columns: repeat(2, 1fr);
    gap: pxtorem(50px);
  }

  @include mq(desktop) {
    column-gap: pxtorem(50px);
    row-gap: pxtorem(60px);
  }

  @include mq(bigscreen) {
    gap: pxtorem(60px);
  }
}

.teaser-grid--triple .teaser-grid__inner {
  grid-template-columns: repeat(1, 1fr);
  row-gap: pxtorem(50px);

  @include mq(tablet) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: pxtorem(50px);
    row-gap: pxtorem(60px);
  }

  @include mq(desktop) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: pxtorem(60px);
    row-gap: pxtorem(80px);
  }
}

.teaser-grid--single .teaser-grid__inner {
  grid-template-columns: repeat(1, 1fr);
  row-gap: pxtorem(50px);

  @include mq(tablet) {
    row-gap: pxtorem(60px);
  }
}

.teaser-grid--single .teaser-grid__article {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @include mq(desktop) {
    grid-template-columns: repeat(2, 1fr);
    gap: pxtorem(60px);
  }
}

.teaser-grid__figcaption,
.teaser-grid__context {
  margin-right: pxtorem(20px);
  margin-left: pxtorem(20px);

  @include mq(tablet) {
    margin-right: 0;
    margin-left: 0;
  }
}

.teaser-grid--single .teaser-grid__context {
  @include mq(tablet) {
    margin-right: pxtorem(120px);
  }

  @include mq(desktop) {
    margin-right: 0;
  }
}

.teaser-grid__figcaption {
  padding-top: pxtorem(8px);
}

.teaser-grid__date {
  display: inline-block;
  padding-top: pxtorem(15px);

  @include mq(tablet) {
    padding-top: pxtorem(20px);
  }

  @include mq(desktop) {
    padding-top: pxtorem(30px);
  }
}

.teaser-grid--single .teaser-grid__date {
  @include mq(desktop) {
    padding-top: 0;
  }
}

.teaser-grid__title {
  margin-top: pxtorem(5px);

  @include mq(tablet) {
    margin-top: pxtorem(10px);
  }

  @include mq(desktop) {
    margin-top: pxtorem(15px);
  }
}

.teaser-grid__text {
  margin-top: pxtorem(5px);

  @include mq(tablet) {
    margin-top: pxtorem(8px);
  }

  @include mq(desktop) {
    margin-top: pxtorem(10px);
  }
}

.teaser-grid__picture::after {
  @include gradient-full;
}

.teaser-grid__button {
  @include container-large;
  @include button-space;

  @include mq(tablet) {
    text-align: center;
  }
}

.teaser-grid__pagination {
  margin-top: pxtorem(85px);

  @include mq(tablet) {
    margin-top: pxtorem(100px);
  }

  @include mq(desktop) {
    margin-top: pxtorem(120px);
  }

  @include mq(bigscreen) {
    margin-top: pxtorem(140px);
  }
}
