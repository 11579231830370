//USE: @include transition;

// EXAMPLE 1: @include transition;
// EXAMPLE 2: @include transition('opacity', '500ms', 'ease-in-out');

// OUTPUT: transition: all, 250ms, ease-in-out;

@mixin transition($property: all, $duration: 250ms, $timing: ease-in-out) {
  transition: $property $duration $timing;
}
