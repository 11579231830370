@use 'scss_settings/settings.fonts' as *;
@use 'scss_settings/settings.paths' as *;
@use 'scss_mixins/tools.fontface' as *;

// GT America Thin
@include fontFace(
  $font-family-america,
  '#{$path-font}GT-America-Thin',
  normal,
  200
);

// GT America Light
@include fontFace(
  $font-family-america,
  '#{$path-font}GT-America-Light',
  normal,
  300
);

// Tobias Light
@include fontFace(
  $font-family-tobias,
  '#{$path-font}Tobias-Light',
  normal,
  300
);
