@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.link-hover' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.typography' as *;

.breadcrumb {
  @include container-large;

  display: none;
  padding-top: pxtorem(10px);
  padding-bottom: pxtorem(20px);
  color: var(--color-background);
  background-color: var(--color-text);

  @include mq(tablet) {
    display: block;
  }
}

.breadcrumb__list {
  display: flex;
}

.breadcrumb__item {
  @include text-small;

  margin-left: pxtorem(5px);
  white-space: nowrap;
  text-transform: uppercase;
}

// .breadcrumb__current-position {
//   @include link-hover('', true, var(--color-background));
// }
