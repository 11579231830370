@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.gradient' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.module-space' as *;

.teaser-switch {
  @include module-space('margin');
}

.teaser-switch__inner {
  display: grid;
  row-gap: pxtorem(40px);

  @include mq(tablet) {
    row-gap: pxtorem(60px);
  }

  @include mq(desktop) {
    row-gap: pxtorem(80px);
  }
}

.teaser-switch__article {
  @include container-large;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 0;

  @include mq(desktop) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: pxtorem(40px);
    row-gap: pxtorem(80px);
  }

  @include mq(bigscreen) {
    column-gap: pxtorem(60px);
  }
}

.teaser-switch__article--right {
  @include mq(desktop) {
    padding-right: 0;
  }

  @include mq(bigscreen) {
    @include container-large;
  }
}

.teaser-switch__article--left {
  @include mq(desktop) {
    padding-left: 0;
  }

  @include mq(bigscreen) {
    @include container-large;
  }
}

.teaser-switch__article--right .teaser-switch__figure {
  order: inherit;

  @include mq(desktop) {
    order: 2;
  }
}

.teaser-switch__figure {
  margin-bottom: pxtorem(20px);

  @include mq(tablet) {
    margin-bottom: pxtorem(20px);
  }

  @include mq(desktop) {
    margin-bottom: 0;
  }
}

.teaser-switch__picture {
  margin-right: -20px;
  margin-left: -20px;

  @include mq(tablet) {
    margin-right: inherit;
    margin-left: inherit;
  }
}

.teaser-switch__picture::after {
  @include gradient-full;
}

.teaser-switch__figcaption {
  margin-top: pxtorem(5px);
  margin-right: pxtorem(10px);

  @include mq(desktop) {
    margin-top: pxtorem(10px);
    margin-right: pxtorem(20px);
  }
}

.teaser-switch__article--left .teaser-switch__figcaption {
  text-align: left;

  @include mq(desktop) {
    margin-right: 0;
    margin-left: pxtorem(20px);
    text-align: right;
  }
}

.teaser-switch__article--left .teaser-switch__context {
  @include mq(desktop) {
    margin-left: pxtorem(65px);
  }

  @include mq(bigscreen) {
    margin-left: pxtorem(80px);
  }
}

.teaser-switch__article--right .teaser-switch__context {
  @include mq(desktop) {
    margin-right: pxtorem(65px);
  }

  @include mq(bigscreen) {
    margin-right: pxtorem(80px);
  }
}

.teaser-switch__header {
  @include container-heading;
}

.teaser-switch__header > h2 {
  @include heading-space;
}

.teaser-switch__title {
  margin-bottom: pxtorem(5px);
  padding-bottom: pxtorem(15px);
  border-bottom: 1px solid var(--color-text);

  @include mq(tablet) {
    margin-bottom: pxtorem(10px);
    padding-bottom: pxtorem(20px);
  }
}

.teaser-switch__button {
  @include button-space;
}
