@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.module-space' as *;
@use 'scss_mixins/tools.transition' as *;
@use 'scss_mixins/tools.typography' as *;

// video border padding

$video-border-biscreen: 50px;
$video-border-desktop: 40px;
$video-border-tablet: 25px;
$video-border-mobile: 10px;

.video {
  @include module-space;
}

// header

.video__header {
  @include container-heading;
}

.video__header > h2 {
  @include heading-space;
}

// video

.video__container {
  @include container-large;
}

.video__inner {
  position: relative;
  padding-right: $video-border-mobile;

  @include mq(tablet) {
    padding-right: $video-border-tablet;
  }

  @include mq(desktop) {
    padding-right: $video-border-desktop;
  }

  @include mq(bigscreen) {
    padding-right: $video-border-biscreen;
  }
}

.video.video--mp4 .video__inner::after,
.video.video--embed .consent-overlay__content::after {
  content: '';
  position: absolute;
  top: $video-border-mobile;
  left: $video-border-mobile;
  z-index: -1;
  height: calc(100% - #{$video-border-mobile});
  padding-bottom: 0 !important;
  border: 2px solid var(--color-text);

  @include mq(tablet) {
    top: $video-border-tablet;
    left: $video-border-tablet;
    width: calc(100% - #{$video-border-tablet});
    height: calc(100% - #{$video-border-tablet});
  }

  @include mq(desktop) {
    top: $video-border-desktop;
    left: $video-border-desktop;
    width: calc(100% - #{$video-border-desktop});
    height: calc(100% - #{$video-border-desktop});
  }

  @include mq(bigscreen) {
    top: $video-border-biscreen;
    left: $video-border-biscreen;
    width: calc(100% - #{$video-border-biscreen});
    height: calc(100% - #{$video-border-biscreen});
  }
}

.video.video--mp4 .video__inner::after {
  width: calc(100% - #{$video-border-mobile});

  @include mq(tablet) {
    width: calc(100% - #{$video-border-tablet});
  }

  @include mq(desktop) {
    width: calc(100% - #{$video-border-desktop});
  }

  @include mq(bigscreen) {
    width: calc(100% - #{$video-border-biscreen});
  }
}

.video__embed .plyr--paused .plyr__controls {
  display: none;
}

.video.video--embed .consent-overlay__content {
  position: relative;
}

.video.video--embed .consent-overlay__content::after {
  width: 100%;
}

.video__figure {
  width: 100%;
}

.video__embed {
  position: relative;
}

.video__plyr {
  width: 100%;
}

// play button

.video__embed-button {
  @include transition;

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: pxtorem(40px);
  height: pxtorem(40px);
  overflow: hidden;
  color: var(--color-text);
  text-decoration: none;
  border: pxtorem(1px) solid var(--color-text);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(4px);

  @include mq(tablet) {
    width: pxtorem(60px);
    height: pxtorem(60px);
  }

  @include mq(desktop) {
    width: pxtorem(80px);
    height: pxtorem(80px);
  }

  @include mq(bigscreen) {
    width: pxtorem(100px);
    height: pxtorem(100px);
  }
}

.video__embed-button:hover,
.video__embed-button:focus-visible {
  background-color: var(--color-text);
}

.video__embed-button--hidden {
  display: none;
}

.video__embed-button > i {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text);
  font-size: pxtorem(18px);

  @include mq(tablet) {
    font-size: pxtorem(27px);
  }

  @include mq(desktop) {
    font-size: pxtorem(36px);
  }

  @include mq(bigscreen) {
    font-size: pxtorem(45px);
  }
}

.video__embed-button:hover > i,
.video__embed-button:focus-visible > i {
  color: var(--color-background);
}

.video__embed-button .icon--video-play {
  padding-left: 5px;

  @include mq(desktop) {
    padding-left: 8px;
  }
}

// copyright

.video__copyright {
  margin-top: pxtorem(5px);
  margin-left: pxtorem(20px);
  padding-bottom: pxtorem(10px);

  text-align: end;

  @include mq(tablet) {
    margin-left: pxtorem(50px);
    padding-bottom: pxtorem(15px);
  }

  @include mq(desktop) {
    margin-left: pxtorem(60px);
    padding-bottom: pxtorem(25px);
  }

  @include mq(bigscreen) {
    margin-left: pxtorem(70px);
    padding-bottom: pxtorem(30px);
  }
}

// context

.video__context {
  @include container-small;

  margin-top: pxtorem(15px);

  @include mq(tablet) {
    margin-top: pxtorem(42px);
  }

  @include mq(desktop) {
    margin-top: pxtorem(52px);
  }
}

.video__title {
  margin-top: pxtorem(10px);

  @include mq(tablet) {
    margin-top: pxtorem(40px);
  }

  @include mq(desktop) {
    margin-top: pxtorem(50px);
  }
}

.video__text {
  margin-top: pxtorem(10px);

  @include mq(tablet) {
    margin-top: pxtorem(20px);
  }

  @include mq(desktop) {
    margin-top: pxtorem(30px);
  }
}

.video__button {
  @include button-space;
}

// consent-overlay
// https://gitlab.3pc.de/frontend/packages/consent-overlay

.consent-overlay {
  --consent-overlay-font-size-basic: 1em;
  --consent-overlay-font-size-text: 0.8em;
  --consent-overlay-font-size-heading: 1.2em;
  --consent-overlay-text-color: #000;
  --consent-overlay-background-color: #fff;
  --consent-overlay-color-controls-active: #fff;
}

.consent-overlay__buttons {
  @include button-space;
}

.consent-overlay.is-active[data-consent-type='plyr'] .consent-overlay__opt-out {
  //  position: absolute;
  display: flex;
  margin-bottom: pxtorem(10px);
  //  transform: translateY(-100%);
}

.consent-overlay.is-active[data-consent-type='embed']
  .consent-overlay__opt-out {
  display: block;
  margin-bottom: pxtorem(10px);
}

// toogle button
.consent-overlay button[data-revoke] {
  border: 0.08em solid currentColor;
}

.consent-overlay button[data-revoke]:hover,
.consent-overlay button[data-revoke]:focus {
  background-color: #333;
}

.consent-overlay button[data-revoke]::after {
  top: 0.14em;
  background-color: #333;
}

.consent-overlay button[data-revoke]::after:hover {
  background-color: #fff;
}

.consent-overlay__toggle {
  display: inline-block;
}

// override consent-overlay css
// TODO: find a better solution
.video
  [data-consent-type='plyr'][data-consent-subtype='vimeo']
  .consent-overlay__content
  .video__figure,
.video
  [data-consent-type='plyr'][data-consent-subtype='youtube']
  .consent-overlay__content
  .video__figure {
  position: static;
  width: auto;
  height: auto;
}
