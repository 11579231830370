@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.gradient' as *;
@use 'scss_mixins/tools.container' as *;
@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.module-space' as *;
@use 'scss_mixins/tools.picture' as *;

.logo-list {
  @include container-max;
  @include module-space('margin');

  position: relative;
}

.logo-list.logo-list--has-image {
  padding-top: pxtorem(60px);
  padding-bottom: pxtorem(60px);

  @include mq(desktop) {
    padding-top: pxtorem(100px);
    padding-bottom: pxtorem(100px);
  }
}

.logo-list__header {
  @include container-heading;
}

.logo-list__header > h2 {
  @include heading-space;
}

.logo__logos {
  @include container-small;
}

.logo__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: pxtorem(20px) pxtorem(30px);

  @include mq(tablet) {
    grid-template-columns: repeat(4, 1fr);
    gap: pxtorem(30px) pxtorem(60px);
  }

  @include mq(desktop) {
    gap: pxtorem(40px) pxtorem(100px);
  }

  @include mq(bigscreen) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.logo-list__picture {
  @include picture-background;
}

.logo-list__picture::before {
  @include gradient-full;
}

.logo-list__image {
  @include image-background;
}
