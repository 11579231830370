@use 'scss_functions/tools.pxtorem' as *;

.form__textarea {
  display: block;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 170px;
  margin-top: 10px;
  padding: pxtorem(5px);
  color: var(--color-text);
  border: pxtorem(2px) solid var(--color-text);
}

// error styles
.form__group--textarea.form__group--error .form__textarea {
  color: var(--color-error-red);
  border-color: var(--color-error-red);
  outline-color: var(--color-error-red);
}
