// USE: @include gradient-full;
// picture::before or ::after { @include gradient-full; }

//EXAMPLE 1: @include gradient-full;
//EXAMPLE 2: @include gradient-full(0.4, var(--color-cyan));

@mixin gradient-full($opacity: 0.2, $color: var(--color-black)) {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: $color;
  opacity: $opacity;
}

// USE: @include gradient-to-top;
// picture::before or ::after { @include gradient-to-top; }
@mixin gradient-to-top {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      to top,
      rgba(51, 51, 51, 0),
      var(--color-grey)
    ),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

// USE: @include gradient-to-bottom;
// picture::before or ::after { @include gradient-to-bottom; }
@mixin gradient-to-bottom {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      to bottom,
      rgba(51, 51, 51, 0),
      var(--color-grey)
    ),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}
