@use 'scss_mixins/tools.button' as *;
@use 'scss_functions/tools.pxtorem' as *;

// See `1-tools/mixins/_tools.button.scss` for mixins in use.
button {
  @include reset-button;
}

button:focus-visible {
  outline: pxtorem(2px) solid currentColor;
  outline-offset: pxtorem(2px);
}
