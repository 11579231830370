@use 'scss_functions/tools.pxtorem' as *;

@use 'scss_mixins/tools.media-query' as *;
@use 'scss_mixins/tools.link-hover' as *;
@use 'scss_mixins/tools.typography' as *;

.link {
  @include link-hover('span');
}

.link.link--active {
  @include link-hover('span', true);
}

.link--intern,
.link--extern,
.link--download {
  @include link-hover('span', true);
}

.link--extern > span,
.link--download > span {
  margin-left: pxtorem(5px);
}

.link--extern > i,
.link--download > i {
  @include text-paragraph;

  display: inline-block;
  transform: translateY(2px);
}

.line {
  padding-bottom: pxtorem(5px);
  background-image: linear-gradient(0deg, currentColor 1px, transparent 0);
  background-repeat: no-repeat;

  @include mq(tablet) {
    padding-bottom: pxtorem(8px);
  }

  @include mq(desktop) {
    padding-bottom: pxtorem(10px);
  }
}

.line:hover,
.line:focus-visible,
.line--active {
  background-image: linear-gradient(0deg, currentColor 2px, transparent 0);
}
