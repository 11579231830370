@use 'scss_functions/tools.pxtorem' as *;

// only for styles that are used to nicely display everything in the 01-base

.base {
  padding: pxtorem(20px);
}

.base-flex {
  display: flex;
  flex-wrap: wrap;
  gap: pxtorem(30px);
}
