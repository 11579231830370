@use 'sass:math';

//USE: @include fixed-ratio(ratio, child selector);

//EXAMPLE 1: @include fixed-ratio(3/4, .child-element);
//EXAMPLE 2: @include fixed-ratio(3/4);
//EXAMPLE 3: @include fixed-ratio;

@mixin fixed-ratio($ratio: math.div(16, 9), $selector: '> *') {
  $selector: $selector;

  position: relative;

  &::after {
    content: '';
    display: block;
    height: 0;
    padding-bottom: calc(1 / $ratio) * 100%;
  }

  #{$selector} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
